import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserBankInfoService } from '@wlp/ui-bs-dashboard';
import { UserInfoService } from '@wlp/ui-bs-login';

import { StatusService } from '@wlp/ui-bs-prospera';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { of } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { MENU_ASIDE } from '../../../../../core/config/menu.config';
import { MENU_ASIDE_PROD } from '../../../../../core/config/menu.config.prod';
import { PermissionsService } from './permissions/permissions.service';
import { managerFeatureService } from '@wlp/ui-bs-manager-feature'
import { Storage } from '@ionic/storage';


@Component({
  selector: 'ui-t-manager',
  templateUrl: './cp-manager.page.html',
  styleUrls: ['./cp-manager.page.scss'],
})
export class CpManagerPage implements OnInit {
  public imgColor: any;
  public logoMenu: string;
  public logoHeader: string;
  public accountData: any;

  public flagDisplayCard: boolean;
  public flagDisplayNotification: boolean;
  public flagGrid: boolean;
  public userProfile: any;
  public menuAside: any[] = [];
  public rawMenuAside: any[] = [];
  public listCardGrid: any;
  public backgroundProfile: string;
  public dataQRCode: object;
  public version = ` <span class="text-right text-white">${environment.appVersion} </span> `;
  public isMenuApplication: any;
  public isVisibleFeesAndTariffs = true;
  public realm: string;
  public secundary: boolean

  constructor(
    private userBankInfoService: UserBankInfoService,
    protected userInfoService: UserInfoService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private myDataService: UiBsMyDataService,
    private statusService: StatusService,
    private permissionService: PermissionsService,
    private menuFeature: managerFeatureService,
    private storage: Storage,
    private featureManager: managerFeatureService
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    // this.userResponse = userBankInfoService.getUserBank();

    const asideTxtColor = this.layoutConfigService.getConfig('wlTheme.aside.text.color');
    this.imgColor = { filter: `${hexToCSSFilter(asideTxtColor).filter.slice(0, -1)}` };

    this.logoMenu = this.layoutConfigService.getConfig('wlTheme.aside.brand.logo');
    this.logoHeader = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.primary');
    this.backgroundProfile = this.layoutConfigService.getConfig('wlTheme.header.profile.background.image');
    this.dataQRCode = {
      width: 100,
      class: 'qrcode',
      colorLight: '#000000',
      colorDark: '#FFFFFF',
      // colorDark: this.layoutConfigService.getConfig('wlTheme.color.primary'),
      // colorLight: this.layoutConfigService.getConfig('wlTheme.color.secondary'),
    };

    if (environment.production) {
      this.rawMenuAside = MENU_ASIDE_PROD;
    } else {
      this.rawMenuAside = MENU_ASIDE;
    }
  }

  async ngOnInit() {
    this.listCardGrid = this.userInfoService.getApplicationAccess();
    if (this.listCardGrid && this.listCardGrid.length > 0) {
      this.isMenuApplication = true;
    }
    this.loadUser();
 
  }

  /**
   * Load menu list
   */
  public loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(async (user) => {
        /*console.log("USER PROFILE: ", user)  */
        this.userProfile = user;
        console.log('Profile', this.userProfile)
        this.loadMenuV2(this.userProfile.uuidAccount, this.userProfile.uuidWhiteLabel)
        // this.loadMenu(this.userProfile.uuidAccount, this.userProfile.uuidWhiteLabel)
        this.loadAccountData();
        // verifia condicao para exibir o menu prospera
        if (this.realm.toUpperCase() === 'PROSPERA') {
          this.getStatus();
        }
 
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  public loadAccountData() {
    this.myDataService.getAccountPaymentPerson(this.userProfile.uuidAccount).subscribe(
      (res) => {
        this.userProfile.documentDefault = res.documentDefault;
        this.userProfile.fullName = res.fullName;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagGrid = false;
    this.flagDisplayCard = this.flagDisplayCard ? false : true;
  }

  public onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagGrid = false;
    this.flagDisplayNotification = this.flagDisplayNotification ? false : true;
  }

  public onFlagGrid() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
    this.flagGrid = this.flagGrid ? false : true;
  }

  /**
   * @description Método responsavel por fechar as notificações
   * e aba de perfil ao clicar fora da div dos itens
   */
  public closeNotifications() {
    if (this.flagDisplayNotification) {
      this.flagDisplayNotification = false;
    }
    if (this.flagDisplayCard) {
      this.flagDisplayCard = false;
    }
    if (this.flagGrid) {
      this.flagGrid = false;
    }
  }

  public onLogout(event) {
    if (event) {
      let redirectUrl = document.location.protocol + '//' + document.location.hostname;

      if (document.location.hostname.includes('localhost')) {
        redirectUrl += ':' + document.location.port;
      }

      this.userInfoService.onLogout(redirectUrl);
    }
  }

  public onChange(event) {
    if (event.blank) {
      window.open(event.url, '_blank');
    } else {
      this.navCtrl.navigateRoot(event.url);
    }
  }

  public myData() {
    this.navCtrl.navigateRoot('/app/my-data');
  }

  public myFeesAndTariffs() {
    this.navCtrl.navigateRoot('/app/fees-tariffs');
  }

  private getStatus() {
    this.statusService
      .getStatus(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .pipe(
        tap((res) => {
          if (this.realm.toUpperCase() === 'PROSPERA') {
            this.setMenuProspera(res.isMigrated, res.isPhysicalPerson, res.request.status);
          }
        }),
        retry(2),
        catchError((_) => {
          return of(null);
        })
      )
      .subscribe();
  }

  private removeMenuProspera() {
    if (this.realm.toUpperCase() !== 'PROSPERA') {
      // this.listCardGrid = this.listCardGrid.filter((obj) => !obj.label.toUpperCase().includes('PROSPERA'));
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('PROSPERA'));
      // this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('CASHBACK'));
    }
  }

  private removeMenuCashback() {
    if (this.realm !== 'prospera' && environment.production) {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('CASHBACK'));
    } else if (this.realm !== 'prospera' && this.realm !== 'psxbank' && !environment.production) {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('CASHBACK'));
    }
  }

  private removeMenuBilletCharging() {
    if (!this.userInfoService.isUserInRole('gtw-billet') && environment.production) {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('COBRANÇA'));
    }
  }

  private removeCredit() {
    if (
      this.realm !== 'bancomin' &&
      this.realm !== 'primorbank' &&
      this.realm !== 'okbank' &&
      this.realm !== 'plusnetworkbank' &&
      this.realm !== 'ac7pay' &&
      this.realm !== 'premierbank' &&
      this.realm !== 'bombank' &&
      this.realm !== 'bankpay' &&
      this.realm !== 'personalbank' &&
      this.realm !== 'vrbank' &&
      this.realm !== 'razonetbank' &&
      this.realm !== 'bansaude' &&
      this.realm !== 'ledbank' &&
      this.realm !== 'tr3bank' &&
      this.realm !== 'ds7bank' &&
      this.realm !== 'flybank' &&
      this.realm !== 'wastebank' &&
      this.realm !== 'shekelbank' &&
      this.realm !== 'ladysbank' &&
      this.realm !== 'hcommercebank' &&
      this.realm !== 'spfcbank' &&
      this.realm !== 'rightbank' &&
      this.realm !== 'ecosolarbank' &&
      this.realm !== 'slipay' &&
      this.realm !== 'keepbank' &&
      this.realm !== '7wbank' &&
      this.realm !== 'parabank' &&
      this.realm !== 'db9digitalbank' &&
      this.realm !== 'fiescmais' &&
      this.realm !== 'ccnetbank' &&
      this.realm !== 'grandbank' &&
      this.realm !== 'umbank' &&
      this.realm !== 'supportpay' &&
      this.realm !== 'realsolarbank' &&
      this.realm !== 'parabank' &&
      this.realm !== 'axybank' &&
      this.realm !== 'everabank' &&
      this.realm !== 'gxbank' &&
      this.realm !== 'imedicalbank' &&
      this.realm !== 'mexbank' &&
      this.realm !== 'axiombank' &&
      this.realm !== 'conceptfinancial' &&
      this.realm !== 'libben' &&
      this.realm !== 'contabilbank' &&
      this.realm !== 'sabank' &&
      this.realm !== 'pwbank' &&
      this.realm !== 'apcbank' &&
      this.realm !== 'implybank' &&
      this.realm !== 'wq3digitalbank' &&
      this.realm !== 'confibank' &&
      this.realm !== 'karebank' &&
      this.realm !== 'eletrobank' &&
      this.realm !== 'spbrbank' &&
      this.realm !== 'ulloobank' &&
      this.realm !== 'sc9bank' &&
      this.realm !== 'ammobank' &&
      this.realm !== 'logbank' &&
      this.realm !== 'vobisbank' &&
      this.realm !== 'nexsobank' &&
      this.realm !== 'sbxbank' &&
      this.realm !== 'seniorbank' &&
      this.realm !== 'tambasafinanceira' &&
      this.realm !== 'auguricredbank' &&
      this.realm !== 'foxxbank' &&
      this.realm !== 'prospipay' &&
      this.realm !== 'sejabank'
    ) {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('CRÉDITO'));
    }
  }

  private removeMenuInsurance() {
    if (
      this.realm.toUpperCase() !== 'LOGBANK' &&
      this.realm.toUpperCase() !== 'BANCOMIN' &&
      this.realm.toUpperCase() !== 'PRIMORBANK' &&
      this.realm.toUpperCase() !== 'SPFCBANK' &&
      this.realm.toUpperCase() !== 'AUTOCRED' &&
      this.realm.toUpperCase() !== 'AUGURICREDBANK' &&
      this.realm.toUpperCase() !== 'TAMBASAFINANCEIRA'
    ) {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('SEGUROS'));
    }
  }

  private removeMenuRecarga() {
    if (this.realm.toUpperCase() === 'PAGSORTE') {
      this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('RECARGA DE CELULAR'));
    }
  }

  private removeMenuRequestCard() {
    if (
      this.realm !== 'psxbank' &&
      this.realm !== 'logbank' &&
      this.realm !== 'londrina' &&
      this.realm !== 'avaibank' &&
      this.realm !== 'sabank' &&
      this.realm !== 'flybank' &&
      this.realm !== 'urbanobank' &&
      this.realm !== 'okbank' &&
      this.realm !== '360bank' &&
      this.realm !== 'bankpay' &&
      this.realm !== 'bpago' &&
      this.realm !== 'bullbank' &&
      this.realm !== 'fcbank' &&
      this.realm !== 'kfpay' &&
      this.realm !== 'karebank' &&
      this.realm !== 'mkpay' &&
      this.realm !== 'plusnetworkbank' &&
      this.realm !== 'premierbank' &&
      this.realm !== 'sccbank' &&
      this.realm !== 'tradepar' &&
      this.realm !== 'prospera' &&
      this.realm !== 'bansaude' &&
      this.realm !== 'fmoney' &&
      this.realm !== 'popibank' &&
      this.realm !== 'kapitolbank' &&
      this.realm !== 'r4bank' &&
      this.realm !== 'iosanbank' &&
      this.realm !== 'ccmaisbank' &&
      this.realm !== 'invbank' &&
      this.realm !== 'begbank' &&
      this.realm !== 'wibbank' &&
      this.realm !== 'saferbank' &&
      this.realm !== 'rttbank' &&
      this.realm !== 'ecommercebank' &&
      this.realm !== 'offerbank' &&
      this.realm !== 'popbank' &&
      this.realm !== 'umbank' &&
      this.realm !== 'whbank' &&
      this.realm !== 'feelbank' &&
      this.realm !== 'bombank' &&
      this.realm !== 'grandbank' &&
      this.realm !== 'personalbank' &&
      this.realm !== 'wastebank' &&
      this.realm !== 'tchenetbank' &&
      this.realm !== 'yetbank' &&
      this.realm !== 'exitobank' &&
      this.realm !== 'keepbank' &&
      this.realm !== 'dinoubank' &&
      this.realm !== 'anjobank' &&
      this.realm !== 'aceitedigital' &&
      this.realm !== 'facilitibank' &&
      this.realm !== '5gbank' &&
      this.realm !== 'imedicalbank' &&
      this.realm !== 'bridgebank' &&
      this.realm !== 'ativabank' &&
      this.realm !== 'h2bank' &&
      this.realm !== 'delabank' &&
      this.realm !== 'ecosolarbank' &&
      this.realm !== 'gxbank' &&
      this.realm !== 'bvmbank' &&
      this.realm !== 'openmeibank' &&
      this.realm !== 'bancomin' &&
      this.realm !== 'ammobank' &&
      this.realm !== 'autocred' &&
      this.realm !== 'contabilbank' &&
      this.realm !== 'rentalizebank' &&
      this.realm !== 'rcdigital' &&
      this.realm !== '5sbank' &&
      this.realm !== 'razonetbank' &&
      this.realm !== 'vobisbank' &&
      this.realm !== 'razonetbank' &&
      this.realm !== 'quality' &&
      this.realm !== 'ladysbank' &&
      this.realm !== 'hcommercebank' &&
      this.realm !== 'mercorabank' &&
      this.realm !== 'slipay' &&
      this.realm !== 'everabank' &&
      this.realm !== 'parabank' &&
      this.realm !== 'acebank' &&
      this.realm !== 'apcbank' &&
      this.realm !== 'db9digitalbank' &&
      this.realm !== 'tambasafinanceira' &&
      this.realm !== 'multiplybank' &&
      this.realm !== 'mexbank' &&
      !(this.realm === 'conceptfinancial' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'spfcbank' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'tlgdigital' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'sejabank' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'eletrobank' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'seniorbank' && this.userInfoService.isUserInRole('gtw-card')) && // REMOVE WHEN ROLLOUT
      !(this.realm === 'veribank' && this.userInfoService.isUserInRole('gtw-card'))
    ) {
      this.menuAside = this.menuAside.filter((arr) => arr.label !== 'Meus Cartões');
    }
  }

  private setMenuProspera(isMigrated, isPhysicalPerson, status) {
    if (this.realm.toUpperCase() !== 'PROSPERA' || !isMigrated || isPhysicalPerson) {
      return;
    }

    let index = null;
    let hasContract = false;

    this.menuAside.forEach((el) => {
      if (el.label.toUpperCase().includes('PROSPERA')) {
        index = this.menuAside.indexOf(el);
      }
      if (el.label.toUpperCase().includes('PROSPERA ENERGIA - DASHBOARD')) {
        hasContract = true;
      }
    });

    if (!hasContract && isMigrated && status !== 'APROVED') {
      this.menuAside.splice(index + 1, 0, {
        url: '/prosperaenergy/dashboard',
        label: 'Prospera Energia - Dashboard',
        icon: 'sunny-outline',
      });
    } else if (hasContract && isMigrated && status === 'APROVED') {
      this.menuAside.splice(index, 1);
    }
  }

  private removeMenuTaxAndTariff() {
    this.isVisibleFeesAndTariffs = environment.wlThemeParameterization.wlTheme.realm !== 'sabank';
  }

  // private removeMenuLiquidation() {
  //   if (!this.userInfoService.isUserInRole('gtw-liquidation') /*&& environment.production*/) {
  //     this.menuAside = this.menuAside.filter((obj) => !obj.label.toUpperCase().includes('COMPENSAÇÃO DE BOLETO'));
  //   }
  // }

  private async parseMenu() {
    this.permissionService.getPermissions().then(async (permissions) => {
      permissions = permissions.map((p) => p.permission);
      await this.storage.set('permissions', permissions)
      

      if (permissions.length > 0) {
        this.menuAside = this.parseMenuFromPermissions(permissions);
      } else {
        this.menuAside = this.parseMenuFromPermissions(['*']);
      }

      // this.removeMenuProspera();
      this.removeMenuBilletCharging();
      this.removeMenuRequestCard();
      // this.removeMenuCashback();
      this.removeMenuTaxAndTariff();
      // this.removeMenuLiquidation();
      // this.removeMenuInsurance();
      // this.removeCredit();
      // this.removeMenuRecarga();
      // this.removeMenuPix();
      // this.removeMenuManageUsers();
    });

    await this.storage.get('permissions')
    .then(res => {
      this.secundary = res[0] === '*' ? false : true
    })
  }

  private isApprover(userPermissions: Array<string>): boolean {
    return (
      userPermissions.indexOf('*') !== -1 ||
      userPermissions.some((p) => p.toLowerCase().includes('approval')) ||
      userPermissions.some((p) => p.toLowerCase().includes('access'))
    );
  }

  private parseMenuFromPermissions(permissions: string[]) {
    for (let rows in this.rawMenuAside) {
      if(this.rawMenuAside[rows].requiredPermission === undefined){
        this.rawMenuAside[rows].requiredPermission = '*'
      }
    }
    return this.rawMenuAside.reduce((acc, cur) => {
      let item;
      if (
        (typeof cur.url === 'string' &&
          (!cur.requiredPermission ||
            (cur.url === '/app/payment/liquidation') ||
            permissions.indexOf(cur.requiredPermission) !== -1 ||
            permissions.indexOf('*') !== -1)) || // isMaster
        (cur.requiredPermission === 'approver' && this.isApprover(permissions))
      ) {
        item = cur;
      } 
      else if (typeof cur.url !== 'string') {
        if(cur.url != null){
          const subMenus = cur.url.filter(
            (it) =>
              !it.requiredPermission ||
              permissions.indexOf(it.requiredPermission) !== -1 ||
              permissions.indexOf('*') !== -1 || // isMaster
              (cur.requiredPermission === 'approver' && this.isApprover(permissions))
          );
          // console.log("subMenus", subMenus)
          if (subMenus.length) {
            item = {
              ...cur,
              url: subMenus,
            };
          }
        }
      }

      return item ? [...acc, item] : acc;
    }, []);
  }

  private loadMenu(uuidAccountPayment: string, uuidWl: string) {
    this.menuFeature.getMenu(uuidAccountPayment, uuidWl)
    .pipe(
      tap((res) => {
        const newMenu = this.rawMenuAside.map(objOriginal => {
          const objNewMenu = res.data.find(objNewMenu => objNewMenu && objOriginal
            && objNewMenu.title && objOriginal.label
            && objNewMenu.title.toUpperCase() === objOriginal.label.toUpperCase());
      
          if (objNewMenu) {
            return { ...objNewMenu, ...objOriginal };
          }
        
          return null;
        }).filter(Boolean);

        res.data.forEach(objNovosCampos => {
          const objExistente = newMenu.find(obj => obj.title.toUpperCase() === objNovosCampos.title.toUpperCase());
          if (!objExistente) {
            newMenu.push(objNovosCampos);
          }
        });

        // this.rawMenuAside = newMenu
        console.log('Old', newMenu)
        // this.rawMenuAside.sort((a,b) => a.sequenceWeb - b.sequenceWeb)
        // this.parseMenu();
      }),
      catchError((err) => {
        console.log(err)
        return err
      })
    )
    .subscribe();
  }

 
  private loadMenuV2(uuidAccountPayment: string, uuidWl: string) {
    this.menuFeature.getMenuV2(uuidAccountPayment, uuidWl)
    .pipe(
      tap(async (res) => {
        const categorys = res.data
        const menusMap = {}
        const menus = categorys.filter(category => {
          if(category.category === 'MENU'){
            category.url = [];

            //mapear o menu pelo seu UUID
            menusMap[category.uuid] = category
            return true
          }
          return false
        })

        //Adicionar os submenus aos menus correspondentes
        categorys.forEach(category => {
          if(category.category === 'PAGE' && category.uuidParent in menusMap) {
            menusMap[category.uuidParent].url.push(
              {
                ...category,
                icon: "wallet-outline"
              }
              );
          } else if (category.category === 'PAGE' && category.uuidParent == null){
            menus.push(category)
          } else if (category.category === 'LINK'){
            menus.push(category)
          }
        });

        menus.sort((a,b) => a.sequenceWeb - b.sequenceWeb);
        //retorn os menus como array
        console.log('Menus', menus)
        this.rawMenuAside = menus
        this.parseMenu();

      }),
      catchError((err) => {
        console.log(err)
        return err
      })
    )
    .subscribe();
  }
}
