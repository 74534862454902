import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
const { PassiveFaceLivenessSdk } = window['@combateafraude/passive-face-liveness'];
const { DocumentDetectorSdk } = window['@combateafraude/document-detector'];


@Component({
  selector: 'modal-combate-a-fraude',
  templateUrl: './modal-combate-a-fraude.component.html',
  styleUrls: ['./modal-combate-a-fraude.component.scss'],
})
export class ModalCombateAFraudeComponent implements OnInit {

  @Input() type: string;
  passiveFace: any;
  sdk: any

  documentData = [];

  constructor(
    public modalController: ModalController,
    private loadingService: LoadingService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    ) {

  }

  ngOnInit(): void {
    this.loadingService.show();
  }

 async ionViewWillEnter() {

    if(this.type == 'passive'){
      await this.configurePassiveLiveSdk();
      // this.PassiveFace();
    } else if(this.type == 'rg' || this.type == 'cnh') {
      await this.configureDocumentSdk();
      this.documentScannerFront(this.type);
    } else {
      this.documentAddressScanner(this.type)
    }

  }

  async configureDocumentSdk() {
    this.sdk = new DocumentDetectorSdk({
      token: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI2MzdiZjY5YzFiODk1ODAwMDhjZGQyNTMifQ.c1shOKyEm9TLDinBP4zzWX84E1zu4P2pUyz3byanyNA`,
      language: `pt_BR`,
      environmentSettings: {
          disableDesktopExecution: false,
      },
      appearenceSettings: {
          hideCaptureTitle: false,
          hideCaptureMask: false,
          hideCameraSwitchButton: false,
          useGenericMask: false,
      },
      capturerSettings: {
          disableAdvancedCapturing: true,
          disableVideoCapturing: true
      },
      textSettings: {
          messages: {
              processMessage: '',
              wrongDocumentMessage: '',
              bothWrongSideMessage: '',
              wrongSideMessage: '',
              lowQualityMessage: '',
              captureFailedMessage: '',
          }
  }
});
  }

  async configurePassiveLiveSdk() {

    let colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.primary');
   
    const options = {
      settings: {
        filter: 'clear',
        language: 'pt_BR'
  },
  startButton: {
    label: 'Iniciar captura',
    color: '#F9F9F9',
    backgroundColor: colorPrimary,
    borderRadius: '0.25rem',
    border: '1px solid #2D994B'
  }
    } 
    this.loadingService.hide();
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI2MzdiZjY5YzFiODk1ODAwMDhjZGQyNTMifQ.c1shOKyEm9TLDinBP4zzWX84E1zu4P2pUyz3byanyNA'
    const useFaceAuthenticator = false;
    const sdkContainer = 'sdk-displayer';
    const personId = '40939339870'
    const sdk = window['FacesSDK'];
    const facesSsdk = await sdk.initializeSdk(token, sdkContainer, useFaceAuthenticator, personId, options);
    const result = await facesSsdk.execute();
    if(result.message == 'failed' || result.message == 'error'){
      this.modalController.dismiss('failed', 'fail')
    }
    if(result.message == 'passed'){
      this.modalController.dismiss(result, 'ok')
    }
    console.log(result)
      }

  async documentScannerFront(type: string) {

    try {
      await this.sdk.initPermissions();
      await this.sdk.initialize();
      const stages =[{mode: 'automatic', attempts: 10, duration: 0}, {mode: 'manual', attempts: 0, duration: 0}];
      const captureSettings = {automaticCaptureTimeoutInSeconds: 0}
      const sdkContainer = document.getElementById('sdk-displayer');
      this.loadingService.hide();
      await this.sdk.capture(sdkContainer, stages, {documentType: type, documentSide: 'front', captureSettings})
      .then(async resFront => {
        this.documentData.push(
          resFront
        )
        await this.sdk.close();
        this.documentScannerBack(type);
      })
    }
    catch (error) {
      this.sdk.dispose();
      console.log(error)
    }

  }

  async documentAddressScanner(type: string) {

    try {
      await this.sdk.initPermissions();
      await this.sdk.initialize();
      const stages =[{mode: 'automatic', attempts: 50, duration: 0}];
      const captureSettings = {automaticCaptureTimeoutInSeconds: 0}
      const sdkContainer = document.getElementById('sdk-displayer');
      this.loadingService.hide();
      await this.sdk.capture(sdkContainer, stages, {documentType: type, documentSide: 'front', captureSettings})
      .then(async resFrontAddress => {
        this.documentData.push(
          resFrontAddress
        )
        await this.sdk.close();
        await this.sdk.dispose();
        this.modalController.dismiss(this.documentData, 'ok')
      })
    }
    catch (error) {
      this.passiveFace.dispose();
      console.log(error)
    }

  }

  async documentScannerBack(type: string) {

    try {
      await this.sdk.initPermissions();
      await this.sdk.initialize();
      const stages =[{mode: 'automatic', attempts: 10, duration: 0}, {mode: 'manual', attempts: 0, duration: 0}];
      const captureSettings = {automaticCaptureTimeoutInSeconds: 0}
      const sdkContainer = document.getElementById('sdk-displayer');
      await this.sdk.capture(sdkContainer, stages, {documentType: type, documentSide: 'back', captureSettings})
      .then(async resBack => {
        this.documentData.push(
          resBack
        )
        await this.sdk.close();
        await this.sdk.dispose();
        this.modalController.dismiss(this.documentData, 'ok')
      })
    }
    catch (error) {
      console.log(error)
      await this.sdk.close();
      await this.sdk.dispose();
      this.modalController.dismiss(error, 'fail')
    }

  }

  async PassiveFace(){
    try{
    await this.passiveFace.initPermissions();
    await this.passiveFace.initialize();
    const stages =[{mode: 'automatic', attempts: 3, duration: 30}, {mode: 'manual', attempts: 0, duration: 0}];
    const sdkContainer = document.getElementById('sdk-displayer');
    const personData = { cpf: '', name: '' };
    this.loadingService.hide();
    await this.passiveFace.capture(sdkContainer, stages, {personData, totalAttempts: 20})
    .then(async res => {
      await this.passiveFace.close();
      await this.passiveFace.dispose();
      this.modalController.dismiss(res, 'ok')
    });
    }
    catch (error) {
      await this.passiveFace.close();
      await this.passiveFace.dispose();
      this.modalController.dismiss(error, 'fail')
    }
  }


  async goBack() {
    if(this.type === 'rg' || this.type === 'cnh') {
      await this.sdk.close();
      await this.sdk.dispose();
    } else {
    await this.passiveFace.close();
    await this.passiveFace.dispose();
    }
    this.modalController.dismiss({}, 'clickClose');
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Important message',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
