import { Component, Input, OnDestroy, OnInit, ViewChild, EventEmitter, Output  } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { FormImportFilesModalPage } from '../../../../modals/import-files-form-modal/import-files-form-modal.page';
import { ImportFilesService } from '../import-files.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiImportFilesLote } from '../import-files-lotes.service';
import { AlertController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';
import { TokenCodeResponse, TokenCodeService } from '@wlp/ui-bs-token';
import { ModalComponent } from '../modal/modal.component';
import { DeviceService, SendCodeService } from '@wlp/ui-bs-device-validation';
import { UiCSendCodeComponent } from '@wlp/ui-c-send-code';
import { AlertAccountService } from '@wlp/ui-bs-alert';
import { ValidSmsPage } from '../../cash-out/valid-sms/valid-sms.page';
import { async } from '@angular/core/testing';
import { DataService } from '../shared';


@Component({
  selector: 'ui-t-import-files-lote-list',
  templateUrl: './import-files-lote-list.page.html',
  styleUrls: ['./import-files-lote-list.page.scss'],

})
export class ImportFilesLotePageList implements OnInit, OnDestroy {
  public formTransfer: FormGroup;
  public errorMessages: any;
  public filesList: Array<any> = [];
  public filteredFilesList: Array<any> = [];
  userProfile: UserInfoProfile;
  public countUsers = 0;
  public showModal = false;
  protected nav;
  public sizeFile: number = 5242880;
  fileName = '';
  public filterInitialDate: Date;
  loginForm: FormGroup;
  pwdIcon = 'eye-outline';
  progressbarValue: number;
  showPwd = false;
  public iconName: string | 'eye-off-outline' | 'eye-outline';
  public iconNamex: string | 'eye-off-outline' | 'eye-outline';
  public showBLC = false;
  showValue: Boolean = true;
  phone = '';
  sub: Subscription;
  cellphone: string;
  public showBLCX = false;
  showValuex: Boolean = true;
  status: any;
  typeTransaction: any;
  userLote: any;
  token = '';
  reload: Boolean = true;
  ismyTextFieldType: boolean;
  data = {
    id: '',
    phone: '',
  };
  uniqueBatchIdentifier: String;
  batchType: String;
  lotes: any = [];
  dataEnd: String;
  dataStart: String;
  currentPage: number;
  maxData: number;
  pageCount: any;
  dataPerPage: number;
  lotesOrigin: any[] = [];
  title:any;
  fileType: String
  fileVersion: String
  startLoader: Boolean = true
 
  parentMessage: any;


  

  statusValue: any[] = [
    { value: 'Nenhum', name: 'Nenhum' },
    { value: 'FIX_INCONSISTENCIES', name: 'Inconsistências' },
    { value: 'READY_FOR_PROCESSING', name: 'Pronto para processar' },
    { value: 'TRANSACTION_NOT_COMPLETED', name: 'Aguardando processamento' },
    { value: 'TRANSACTION_IN_PROCESSING', name: 'Em processamento' },
    { value: 'TRANSACTION_COMPLETED', name: 'Processado' },
  ];

  val: any[] = [
    { id: 0, name: 'Nenhum' },
    { id: 1, name: 'Geral', checked: false },
    { id: 2, name: 'TED', checked: false },
    { id: 3, name: 'Pix (Conta Bancária)', checked: false },
    { id: 4, name: 'Pix (Chave)', checked: false },
    { id: 5, name: 'Recarga de Celular', checked: false },
  ];




  constructor(
    private formBuilder: FormBuilder,
    private importFilesService: ImportFilesService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: ActivatedRoute,
    private modalController: ModalController,
    public apiLotes: ApiImportFilesLote,
    private alertController: AlertController,
    private tokenCodeService: TokenCodeService,
    private alertAccountService: AlertAccountService,
    private dataService: DataService,
    private route: Router,

  ) {
    this.loginForm = this.formBuilder.group({
      teste: ['70.00'],
      password: ['60.00'],
    });
    this.iconName = 'eye-off-outline';
    this.iconNamex = 'eye-off-outline';
    this.currentPage = 1;
    this.dataPerPage = 10;
    this.maxData = 0;
  }

  ngOnInit() {
    this.uniqueBatchIdentifier = this.router.snapshot.params.uniqueBatchIdentifier;
    this.batchType = this.router.snapshot.params.batchType;
    this.fileType = this.router.snapshot.params.fileType;

    this.title = this.getTitle(this.batchType)

   

    this.loadUser();

  
  }

  reloadPageChild() {
    this.dataService.sendMessage("callback in action");
  }
 

  ngOnDestroy() {
   this.startLoader = false
  }

  exit(){

    this.reloadPageChild()

    this.route.navigateByUrl(`/app/batch/${this.batchType}`);

  }

  
  async search(){
    if((this.dataStart != null && this.dataEnd == null)||(this.dataStart != null && this.dataEnd == null)){
      const alert = await this.alertController.create({
        header: `Por favor, preencher todos os campos de data!`,
        buttons: ['OK'],
      });
 
      (await alert).present();
    }else{
    
  
      if(this.dataStart != null && this.dataEnd != null && this.dataStart > this.dataEnd) {
        const alert = await this.alertController.create({
          header: `Por favor, preencher data inicial menor que data final!`,
          buttons: ['OK'],
        });
   
        (await alert).present();
      }else{


        this.apiLotes.getTransferDate(this.uniqueBatchIdentifier, this.dataStart, this.dataEnd, this.status).subscribe((res:any)=>{
          console.log(res)
          this.lotes = res.data
          this.maxData = res.meta.itemCount
          this.currentPage = res.meta.page
          this.pageCount= res.meta.pageCount
          this.lotesOrigin = res.data
        })


      }

   
     
    // }
  }
}

  onSelectedStart(dateSelected: moment.Moment = null) {

    this.dataStart = dateSelected != null ?  dateSelected.toDate().toISOString().substring(0, 10) : null
    

   }

   onSelectedEnd(dateSelected: moment.Moment = null){
  
    this.dataEnd = dateSelected!= null ? dateSelected.toDate().toISOString().substring(0, 10) : null

   }



  getDataUser() {}

  getTitle(batch){
    if(batch == "payment"){
      return "Pagamento em lote"
    }else if(batch == "transfer"){
      return "Transferência em lote"
    }else if(batch == "charge"){
      return "Cobrança em lote"
    } 

  }

  togglemyPasswordFieldType() {
    this.ismyTextFieldType = !this.ismyTextFieldType;
  }

  getLoteFile(file) {
    this.apiLotes.getLoteFileVersion(file).subscribe((res) => {

    });
  }
  public showBalance() {
    if (this.iconName === 'eye-off-outline') {
      this.iconName = 'eye-outline';
      this.showBLC = true;
      this.showValue = false;
    } else {
      this.iconName = 'eye-off-outline';
      this.showBLC = false;
      this.showValue = true;
    }
  }

  public showBalancex() {
    if (this.iconNamex === 'eye-off-outline') {
      this.iconNamex = 'eye-outline';
      this.showBLCX = true;
      this.showValuex = false;
    } else {
      this.iconNamex = 'eye-off-outline';
      this.showBLCX = false;
      this.showValuex = true;
    }
  }

  reloadBalance() {}

  togglePwd() {
    this.showPwd = !this.showPwd;
    this.pwdIcon = this.showPwd ? 'eye-off-outline' : 'eye-outline';
  }

  return(){

  }

  async handleChangeStatus(e) {

    if (e.detail.value == 'Nenhum') {
    } else {
    }
  }

  async handleChangeTransaction(e) {
   

    if (e.detail.value == 'Nenhum') {
    } else {
    }
  }

  public filterReceipt(value) {}

  loadTransaction() {
    this.apiLotes.getLoteFileVersion(this.uniqueBatchIdentifier).subscribe((res: any) => {

      this.lotes = res.data;
      this.maxData = res.meta.itemCount
      this.currentPage = res.meta.page
      this.pageCount= res.meta.pageCount
      this.lotesOrigin = res.data
 
    });
  }

  getFilePage(page){
    this.apiLotes.getLoteFileVersion(this.uniqueBatchIdentifier, page).subscribe((res: any) => {
      this.lotes = res.data;
      this.maxData = res.meta.itemCount
      this.currentPage = res.meta.page
      this.pageCount= res.meta.pageCount
      this.lotesOrigin = res.data
 
    });
  }

  

  async searchLote(){
    if(this.dataStart == null || this.dataEnd == null){
      const alert = await this.alertController.create({
        header: `Por favor, preencher todos os campos de data!`,
        buttons: ['OK'],
      });
  
      (await alert).present();
    }else{
     
   
      if(this.dataStart > this.dataEnd) {
        const alert = await this.alertController.create({
          header: `Por favor, preencher data inicial menor que data final!`,
          buttons: ['OK'],
        });
    
        (await alert).present();
      }else{
       


 this.apiLotes.getLoteDate(this.userProfile.uuidAccount, this.dataStart, this.dataEnd, this.status).subscribe((res:any)=>{

        this.maxData = res.meta.itemCount
      this.currentPage = res.meta.page
      this.pageCount= res.meta.pageCount
      this.lotes = res.data

      })

      }

   
     
    }
  }






  loadUser() {

    if(this.startLoader){

    
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;

        this.apiLotes.getStatusLote(this.userProfile.uuidAccount, this.uniqueBatchIdentifier).subscribe((res: any) => {
     
          this.userLote = res.data[0];
          this.loadTransaction();
   
          this.userLote.status == 'IN_ANALYSIS' ? (
            setTimeout(() => {
             this.loadUser()
          }, 15000)
          ) : null
      
        
          
        });
      })
      .catch((error) => {
        alert(error);
      });
    }
  }

 


  restartProgress() {
    // this.sub.unsubscribe();
  }

  async showMessage(message, title){
    const alert = await this.alertController.create({
      header: `${title}`,
      message: `${message}`,
      buttons: ['Fechar'],
    });

    await alert.present();

    
  }

  async resendCode(data) {
    const modalToken = await this.modalController.create({
      component: ValidSmsPage,
      cssClass: 'modal-resume',
    });

    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {


        this.apiLotes
          .postProcess(data.data[0].token, this.uniqueBatchIdentifier, this.userProfile.numberAccountBank)
          .subscribe(
            (data:any) => this.showMessage(data.message, 'Sucesso'),
            err => this.showMessage("Não foi possível processar o lote. Por favor, tente novamente mais tarde.", 'Atenção!'),
            () => console.log('sucesso')
          );

          this.modalController.dismiss();
      }
      
    });

    return await modalToken.present();

    // const modal = await this.modalController.create({
    //   component: ModalComponent,
    //   backdropDismiss: false,
    //   componentProps: {
    //     fileVersion: this.v,
    //     type: 9,
    //   }

    // });

    // modal.onDidDismiss().then((event) => {
    //   if (event.data.success === true) {
    //     this.refresh();
    //   }
    // });
    // return await modal.present();
    // this.loadTokenCode();
    // this.restartProgress();
  }


  async presentAlert() {}

  // reloadLote(){
  //   this.apiLotes.getLoteList(this.userProfile.uuidAccount, this.filesTypes).subscribe((res)=>{

  //   console.log(res)

  //   })
  // }

  onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      const formData = new FormData();

      formData.append('thumbnail', file);

      // const upload$ = this.http.post("/api/thumbnail-upload", formData);

      // upload$.subscribe();
    }
  }

  async refresh() {
    if (!this.loadingService.isLoading) {
      await this.loadingService.show();
    }
    const filter = {};
    const enumStatus = {
      IN_PROGRESS: 'Em Processamento',
      PROCESSING_SUCCESSFUL: 'Processado com Sucesso',
      PROCESSING_WITH_ERRORS: 'Processado com Erros',
      INVALID_FILE: 'Arquivo Inválido',
    };
    try {
      const res = await this.importFilesService.getImportFiles(this.userProfile, filter);
      this.filesList = res
        .map((it) => ({
          ...it,
          parsedStatus: enumStatus[it.status],
          isValid: it.status !== 'INVALID_FILE',
        }))
        .sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
      this.filteredFilesList = this.filesList;
      this.countUsers = this.filesList.length;
    } finally {
      await this.loadingService.hide();
    }
  }

  async uploadFile() {
    this.handleShowModal();
  }

  infiniteScrollEvent(event: Event) {
    console.log(event);
  }

  filter(event) {
    const val = event.toLowerCase() || '';

    this.filteredFilesList = this.filesList.filter((d) => {
      return (
        `
        ${d.name}
        ${d.type}
        ${d.parsedStatus}
      `
          .toLowerCase()
          .indexOf(val) !== -1
      );
    });
  }

  agroupLote(fileVersion, uuid) {
    this.apiLotes.putAgroupLote(fileVersion, uuid).subscribe(async (response: any) => {
      if (response.message == 'Transações agrupadas.') {
        this.loadTransaction();
      } else {
        const alert = await this.alertController.create({
          header: 'Atenção',
          message: `${response.message}`,
          buttons: ['Ok'],
        });

        await alert.present();
      }
    });
  }

  ungroupLote(fileVersion, uuid) {
    this.apiLotes.putUngroupLote(fileVersion, uuid).subscribe(async (response: any) => {
      if (response.message == 'Transações desagrupadas.') {
        this.loadTransaction();
      } else {
        const alert = await this.alertController.create({
          header: 'Atenção',
          message: `${response.message}`,
          buttons: ['Ok'],
        });

        await alert.present();
      }
    });
  }

  async downloadFileToCorrect(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'errors');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_errors.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  async downloadErrorsFile(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'errors');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_errors.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  async downloadResumeFile(importFile) {
    const response: any = await this.importFilesService.getDownloadResumeFile(importFile.uuid, 'complete');
    const objectUrl = window.URL.createObjectURL(response);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = `${importFile.name}_complete.csv`;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  public async handleShowModal() {
    const modal = await this.modalController.create({
      component: FormImportFilesModalPage,
      cssClass: 'modal-custom-class',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((event) => {
      if (event.data.success === true) {
        this.refresh();
      }
    });
    return await modal.present();
  }

  public dateControl(date: any): string {
    try {
      return `${moment(date).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  public getHourDate(date: any): string {
    try {
      return `${moment(date).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }

  
  deleteTransfe(uuid){

    this.apiLotes.deleteTransaction(uuid).subscribe( async result => {


      const alert =  await this.alertController.create({
        header: `Excluido com sucesso `,
        buttons: ['OK'],
      });
  
      (await alert).present();
      await this.loadUser()

    },
    error => {
     alert("algo deu errado, consulte o administrador!")
    },)
  
  }



  async deleteTransfer(data){

    const alert = await this.alertController.create({
      header: 'Atenção',
      message: `Deseja realmente excluir essa transação?`,
      buttons: [
        {
          text: 'Não',
          role: 'cancelar',
          handler: () => {
            return null
          },
        },
        {
          text: 'Sim',
          role: 'confirmar',
          handler: () => {
            this.deleteTransfe(data.uuid)
          },
        },
      ],
    });

    await alert.present();

  }
  
  

  async add() {

    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-custom-class-update',
      backdropDismiss: false,
      componentProps: {
        type: 3,
        uniqueBatchIdentifier: this.uniqueBatchIdentifier,
        batchType: this.batchType,
        typeChose: -1,
        fileType: this.userLote.fileType
      },
    });

    modal.onDidDismiss().then((event) => {
      this.loadUser()
    });
    return await modal.present();
  }

  async process() {
    const alert = await this.alertController.create({
      header: 'Atenção',
      message: ` <ion-row>
      <div class="button-token">
        <ion-button (click)="sendCodePhone()" shape="round" color="primary">Enviar token para ****</ion-button>
      </div>
    </ion-row>`,
      buttons: ['Cencelar', 'Ok'],
    });

    await alert.present();
  }

  // sendCodePhone() {
  //   if (this.phone) {
  //     this.deviceService.createDeviceMigration(this.data)
  //       .then(
  //         (res) => {
  //           this.presentAlert('Atenção', 'Código enviado, aguarde!', ['OK'])
  //           this.sendCodeService.setCelPhone(this.phone);
  //         },
  //         (err) => {
  //           this.deviceService.getErrorDevice(err);
  //         }
  //       );
  //   }
  //   else {

  //   }
  // }

  async eyeProcess(data) {
    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-custom-class-update',
      backdropDismiss: false,
      componentProps: {
        type: 4,
        data: data,
        batchType: this.batchType,
        typeChose: -1,
      },
    });

    modal.onDidDismiss().then((event) => {
      this.loadUser()
     
    });
    return await modal.present();
  }

  async editProcess(data) {
    const modal = await this.modalController.create({
      component: ModalComponent,
      cssClass: 'modal-custom-class-update',
      backdropDismiss: false,
      componentProps: {
        type: 5,
        data: data,
        batchType:this.batchType,
        typeChose: -1,

      },
    });

    modal.onDidDismiss().then((event) => {
      this.loadUser()
    });
    return await modal.present();
  }
}
