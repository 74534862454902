import { environment } from 'src/environments/environment';

import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController, AlertController } from '@ionic/angular';
import { AccountPaymentTypeResponse, AccountTypeService, CreateAccountService, QuizService } from '@wlp/ui-bs-signup';
import { Storage } from '@ionic/storage';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-modal-flow',
  templateUrl: './modal-flow.component.html',
  styleUrls: ['./modal-flow.component.scss'],
})
export class ModalFlowComponent implements OnInit {
  @Input() public env: any;
  @Input() public response: any;

  public status: any;
  public username: any;
  public typeflow: any;
  public typeflowOne: any;
  public typeflowTwo: any;
  public firstname: string;
  public isTypeAvaiable: boolean;
  public accountNum: number;
  public listAccountType: AccountPaymentTypeResponse[];
  private quizData: any;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountTypeService: AccountTypeService,
    private createAccountService: CreateAccountService,
    private toastController: ToastController,
    private storage: Storage,
    private alertController: AlertController,
    private quizService: QuizService,
    private router: Router,
    private load: LoadingService
  ) {}

  ngOnInit() {
    this.isTypeAvaiable = false;
    this.accountNum = 0;
    if (this.response.length === 1) {
      this.isTypeAvaiable = false;
      this.status = this.response[0].status;
      this.setFlowPerson(this.response[0], 0);
    } else if (this.response.length > 1) {
      this.isTypeAvaiable = true;
      this.response.forEach((account) => {
        this.accountNum = this.accountNum + 1;
        this.setFlowPerson(account, this.accountNum);
      });
      // this.setFlowPerson(this.response[0], 1);
      // this.setFlowPerson(this.response[1], 2);
    }

    this.loadAccountType();
    this.checkFlow();
  }

  async continueFlow(resp) {
    sessionStorage.setItem('onboarding_light', 'false')
    // alteracao no if do resp.lastStep, agora verificando somente se este existe.
    try {
      this.createAccountService.processPreAccount(resp);
      if (resp.status === 'IN_ONBORDING') {
        if(resp.lastStep === 'quiz-answer?a=a' || resp.lastStep === "birth-date?a=a"){
          this.navCtrl.navigateRoot('birth-date');
        } else
        if (resp.lastStep === 'upload-documents?a=a' || resp.lastStep === 'choose-document?a=a') {
          this.createAccountService.findAccountTypeByUuid(resp._id).subscribe(({ accountType }) => {
            const typeFlow = resp.accountType.typeFlow;
            this.accountTypeService.temporaryTypeAccount(resp.accountType.typeFlow);

            if (typeFlow === 'PHHYSICAL_PERSON' || typeFlow === 'PHYSICAL_PERSON' || typeFlow === 'MEI') {
              this.navCtrl.navigateRoot('document-type');
            } else if (typeFlow === 'LEGAL_PERSON') {
              this.navCtrl.navigateRoot('partners');
            } else if (typeFlow === 'LIBERAL_PROFESSIONAL') {
              this.navCtrl.navigateRoot('professional-type');
            }
          });
        } else {
          this.createAccountService.findAccountTypeByUuid(resp._id).subscribe(({ accountType }) => {
            if (resp.accountType) {
              this.accountTypeService.temporaryTypeAccount(resp.accountType.typeFlow);
            } else {
              //console.log('accountType: not defined');
            }

            let lastStep = String(resp.lastStep);

            if (lastStep.includes('add-partner')) {
              // fix problem with differente names in app onboarding and webbanking
              lastStep = 'partners?a=a';
            }

            this.navCtrl.navigateRoot(lastStep).catch((err) => {
              this.showToast(
                `Falha ao continuar abertura de conta já iniciada. Entre em contato com nosso suporte. (Err. call_invalid_step)`,
                7000
              );
              this.navCtrl.navigateRoot('');
            });
          });
        }
      } else if (resp.status === 'REPROVED') {
        this.navCtrl.navigateRoot('status-account');
      }  else if(resp.status === 'IN_ONBOARDING_LIGHT'){
        sessionStorage.setItem('onboarding_light', 'true')
        this.load.show()
        this.getQuiz();
     
    } else {
        this.navCtrl.navigateRoot('status-account');
      }
      this.dismiss();
    } catch (err) {
      this.showToast(`Falha ao continuar abertura de conta já iniciada. Entre em contato com nosso suporte.`, 7000);
      this.navCtrl.navigateRoot('');
    }
  }

  async getQuiz() {

    let onboardingId
    let cpfValue
    let emailValue
    let phoneValue
    let idDataFlow
    let typeFlow
    let birthDay
    let cep

    onboardingId = sessionStorage.getItem('keyForm')


    cpfValue = sessionStorage.getItem('cpfValue')

    emailValue = sessionStorage.getItem('emailValue')

    phoneValue = sessionStorage.getItem('phoneValue')

    idDataFlow = sessionStorage.getItem('idDataFlow')

    typeFlow = sessionStorage.getItem('typeFlow')

    birthDay = sessionStorage.getItem('birthDay')

    cep = sessionStorage.getItem('cep')

    let body = {
      "onboardingId": onboardingId,
      "parameters": {
        "campaignId": idDataFlow,
        "password": null,
        "parameters": [
          {
            "name": "CPF / CNPJ",
            "value": cpfValue
          },
          {
            "name": "Nascimento",
            "value": birthDay
          },
          {
            "name": "Endereço",
            "value": emailValue
          },
          {
            "name": "Telefone Completo",
            "value": phoneValue
          },
          {
            "name": "CEP",
            "value": cep
          }
        ]
      }
    }

    this.quizService.verifyAccountQuiz(body)
    .pipe(
    tap((res) => {
      this.load.hide();
      if(res.status === 'Negative'){
        // this.showAlertError(res.executionMessage)
      } else if(res.status === 'Paused') {
        this.quizData = res
     this.router.navigate(['quiz-answer'], {
      state: {
        quizData: this.quizData,
        typeFlow: typeFlow
      },
    });
      } else {
        if (typeFlow === 'PHHYSICAL_PERSON' || typeFlow === 'PHYSICAL_PERSON') {
          this.navCtrl.navigateRoot('document-type')
        }
        if (typeFlow === 'LEGAL_PERSON') {
          this.navCtrl.navigateRoot('documents-partner')
        }
      }

    }),
    catchError((err) => {
      this.showAlertError('Tivemos um problema, por favor tente novamente.')
      return of(null);
    })
    )
    .subscribe();
  }

  async showAlertError(message) {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: message,
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

  newFlowSameUser(data: any) {
    if(this.hasAccount(this.response)) {
      this.confirmNewOnboaridng();
    } else {
      this.navCtrl.navigateRoot('create-account');
      this.dismiss();
    }

  }

  hasAccount(accounts: any): boolean {
    for (const account of accounts) {
      if (account.status === "IN_ONBORDING") {
        return true;
      }
    }
    return false;
  }

  async confirmNewOnboaridng() {
    const alert = await this.alertController.create({
      header: 'Atenção',
      subHeader: 'Ao criar uma nova conta, os dados dos cadastros anteriores em andamento serão automaticamente substituídos.',
      buttons: [
        {
        text: 'OK',
        role: 'confirm',
        handler: () => {
          this.navCtrl.navigateRoot('create-account');
          this.dismiss();
        }
        }
      ],
    });

    await alert.present();
  }




  checkFlow() {
    document.getElementsByTagName('body')[0].classList.remove('simple-typeflow');
    document.getElementsByTagName('body')[0].classList.remove('multiple-typeflow');

    this.createAccountService.findAccountTypeByUuid(this.response[0]._id).subscribe((res) => {
      /* //console.log('checkFlow', res);
      //console.log('checkFlow', this.response); */
      if (
        this.response.length === 1 &&
        // this.response[0].status !== 'FINISHED' &&
        res.accountType &&
        res.accountType.typeFlow !== undefined
      ) {
        /* document.getElementsByTagName('body')[0].classList.add('no-typeflow'); */
        document.getElementsByTagName('body')[0].classList.add('multiple-typeflow');
      } else if (this.response.length > 1) {
        /* //console.log('checkFlow 2'); */
        document.getElementsByTagName('body')[0].classList.add('multiple-typeflow');
      } else {
        document.getElementsByTagName('body')[0].classList.add('simple-typeflow');
      }
    });
  }

  setFlowPerson(resp, type) {
    this.status = resp.status;
    console.log('Response', this.response)

    Promise.all([
      this.createAccountService.loadPreAccount(resp._id),
      this.createAccountService.findAccountTypeByUuid(resp._id).toPromise(),
    ]).then(async (res: any) => {
      // await this.storage.set('cpfValue', res[0].cpf)
      // await this.storage.set('emailValue', res[0].email)
      // await this.storage.set('uuidWhiteLabel', this.response[0].uuidWhiteLabel)
      // await this.storage.set('idDataFlow', res[1].accountType.idDataFlow)
      // await this.storage.set('typeFlow', res[1].accountType.typeFlow)

      sessionStorage.setItem('cpfValue', res[0].cpf)
      sessionStorage.setItem('emailValue', res[0].email)
      sessionStorage.setItem('uuidWhiteLabel', this.response[0].uuidWhiteLabel)
      sessionStorage.setItem('keyForm', this.response[0]._id)
      sessionStorage.setItem('idDataFlow', res[1].accountType.idDataFlow)
      sessionStorage.setItem('typeFlow', res[1].accountType.typeFlow)
      sessionStorage.setItem('birthDay', res[0].birthDay)
      sessionStorage.setItem('cep', res[0].cep)


      this.username = res[0].name;
      // this.firstname = this.username.substr(0, this.username.indexOf(' '));
      if (this.username && this.username !== undefined) {

        this.firstname = this.username.split(' ')[0];

      }

      // //console.log('setFlowPerson', res[1].accountType, 'type: ', type);
      const accountTypeName = res[1].accountType ? res[1].accountType.name : '';

      if (type === 0) {
        this.typeflow = { nameType: accountTypeName, statusType: resp.status, resp };
      } else if (type === 1) {
        this.typeflowOne = { nameType: accountTypeName, statusType: resp.status, resp };
      } else if (type >= 2) {
        this.typeflowTwo = { nameType: accountTypeName, statusType: resp.status, resp };
      }
    });
  }

  setFlowPhysicalPerson() {
    this.accountTypeService.temporaryTypeAccount('PHHYSICAL_PERSON');
    this.dismiss();
  }

  setFlowLegalPerson() {
    this.accountTypeService.temporaryTypeAccount('LEGAL_PERSON');
    this.dismiss();
  }
  setFlowMei() {
    this.accountTypeService.temporaryTypeAccount('MEI');
    this.dismiss();
  }
  setFlowLiberalProfessional() {
    this.accountTypeService.temporaryTypeAccount('LIBERAL_PROFESSIONAL');
    this.dismiss();
  }

  gotoLogin() {
    this.navCtrl.navigateRoot('/');
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list) => (this.listAccountType = list))
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  isContinueOnboarding(status) {
    return (
      status === 'REPROVED' ||
      status === 'IN_ONBORDING' ||
      status === 'APPROVED_TOPAZIO' ||
      status === 'APPROVED_BACKOFFICE' ||
      status === 'APPROVED_COMPLIANCE' ||
      status === 'IN_ANALYSIS_TOPAZIO' ||
      status === 'IN_ANALYSIS_BACKOFFICE' ||
      status === 'IN_ANALYSIS_COMPLIANCE' ||
      status === 'DOCUMENT_LEGAL_PERSON_REPROVED' ||
      status === 'DOCUMENT_PHYSICAL_PERSON_REPROVED' ||
      status === 'REGISTRATION_DATA_LEGAL_PERSON_INVALID' ||
      status === 'REGISTRATION_DATA_PHYSICAL_PERSON_INVALID' ||
      status === 'IN_ONBOARDING_LIGHT'

    );
  }

  private async showToast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message,
      duration,
    });
    toast.present();
  }
}
