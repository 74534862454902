import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountBalanceService } from '@wlp/ui-bs-cashin';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import * as   html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { log } from 'util';



@Component({
  selector: 'app-modal-pdf-generate',
  templateUrl: './modal-pdf-generate.component.html',
  styleUrls: ['./modal-pdf-generate.component.scss'],
})
export class ModalPdfGenerateComponent implements OnInit {

  datePeriod: string;
  dataFile: string;

  @Input() listStatmentShow: Array<any>
  @Input() userProfile: any;
  @Input() printPeriodPdfShow: any;
  @Input() banknameShow: any;

  company: any;
  public balance: string;
  public balanceSubject$: any;
  private today = new Date();
  companyName:any;

  dayName = new Array(
    'domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'sábado'
  );
  monName = new Array(
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro'
  );

  now = new Date();

  constructor(
    private myDataService: UiBsMyDataService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private accountBalanceService: AccountBalanceService,
    private headerInfoService: UICHeaderUserInfoService,
    private modal: ModalController,
    private layoutConfigService: LayoutConfigService
    
    
  ) {
    this.company = this.layoutConfigService.getConfig('wlTheme.company');

    const userDetails = this.userInfoService.getInfoProfile();

    userDetails.then((user) => {        
      this.userProfile = user;
      this.myDataService
          .getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {
              this.companyName = data
            }),
          )
          .subscribe();

  }) 
  }

  ngOnInit() {
    this.balance = '';

    this.loadInitialData();
    this.loadValueUpdate();

    let hours;
    let minute;
    let seconds;

    if (this.now.getHours() < 10) {
      hours = '0' + this.now.getHours();
    } else {
      hours = this.now.getHours();
    }

    if (this.now.getMinutes() < 10) {
      minute = '0' + this.now.getMinutes();
    } else {
      minute = this.now.getMinutes();
    }

    if (this.now.getSeconds() < 10) {
      seconds = '0' + this.now.getSeconds();
    } else {
      seconds = this.now.getSeconds();
    }

    this.datePeriod =
    this.dayName[this.now.getDay()] +
    ', ' +
    this.now.getDate() +
    ' de ' +
    this.monName[this.now.getMonth()] +
    ' de ' +
    this.now.getFullYear() +
    ' às ' +
    hours +
    ':' +
    minute 
    // ':' 
    // seconds;

  this.dataFile =
    this.now.getFullYear().toString() + '_' + this.now.getMonth().toString() + '_' + this.now.getDate().toString();
    
  }

  loadInitialData(): void {
    this.headerInfoService.loadUser();
    this.accountBalanceService.getBalanceSubject().pipe(tap(data => {
      this.balanceSubject$ = String(data);
    })).subscribe();
  }

  loadValueUpdate(): void {
    this.headerInfoService.getValueObservable().subscribe(res => this.loadInitialData());
  }

  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  /**
 * apresentacao da data na tabela
 * @param date
 */
  public getHourDate(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }

  private async generatePDF() {
    await this.loadingService.show();

    let data = new Date();
    let dataFormatada = ((data.getDate())) + "-" + ((data.getMonth() + 1)) + "-" + data.getFullYear();

    const options = {
      // margin: 1,
      filename: `extrato_${(this.userProfile.nameFirst as string).toLowerCase()}_${dataFormatada}`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2},
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape'},
      pagebreak: { avoid: 'tr' }
    };

    const element: Element = document.getElementById('table-extract');

    html2pdf()
      .from(element)
      .set(options)
      .save();

    await this.loadingService.hide();
    await this.dismiss()
  }

  async dismiss() {
    await this.modal.dismiss();
  }

}
