import { interval, of, Subscription } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AlertAccountService } from '@wlp/ui-bs-alert';
import { DeviceService, SendCodeService } from '@wlp/ui-bs-device-validation';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { UiCSendCodeComponent } from '@wlp/ui-c-send-code';
import { catchError, tap } from 'rxjs/operators';
import { sendCodeV2 } from '@wlp/ui-bs-token/lib/dto/send-code.interface';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { validateCode } from '@wlp/ui-bs-token/lib/dto/validate-code.interface';
import { encryptDataService } from '../../../../functions/encrypt/encrypt.page';
import { environment } from "src/environments/environment"

@Component({
  selector: 'app-valid-sms-user',
  templateUrl: './valid-sms.page.html',
  styleUrls: ['./valid-sms.page.scss'],
})
export class ValidSmsUserPage implements OnInit {
  brand: string;
  sub: Subscription;
  errorMessages: any;
  cellphone: string;
  isDisabled: boolean;
  formDevice: FormGroup;
  formSendCode: FormGroup;
  progressbarValue: number;
  showGroupButtons: boolean;
  navigationBackground: string;
  userProfile: UserInfoProfile;
  token = '';
  lastCardDigits: string

  sendType: string
  sendFor: string
  isSend = false
  progressValue = 1
  validType: boolean

  code: any[] = [null, null, null, null, null];
  digits: number[] = [1, 2, 3, 4, 5];

  @ViewChild('cd', { static: false }) public sendToken: UiCSendCodeComponent;
  @ViewChildren('codigoInput') codigoInputs: QueryList<ElementRef>;

  constructor(
    private myDataService: UiBsMyDataService,
    private navCtrl: NavController,
    public deviceService: DeviceService,
    public toastController: ToastController,
    private sendCodeService: SendCodeService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private alertAccountService: AlertAccountService,
    private tokenCodeService: TokenCodeService,
    private modalController: ModalController,
    private device: Device,
    private toast: ToastController,
    private encrypt: encryptDataService
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    const colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.primary');
    document.documentElement.style.setProperty('--toggleLineColor', this.hexToRgba(colorPrimary, 0.3))
  }

  ngOnInit(): void {
    this.startProgressBar();
    this.loadUser();
    this.isDisabled = false;
    this.progressbarValue = 1;
    this.formDevice = this.deviceService.getForm();
    this.formSendCode = this.sendCodeService.getForm();
    this.errorMessages = this.deviceService.getErrorFormMessagen();
    this.errorMessages = this.sendCodeService.getErrorFormMessage();
    this.deviceService.updateForm();
    this.showGroupButtons = true;
  }

  ionViewWillEnter() {
    // this.startProgress();
    // this.sendToken.countdown.restart();
    // this.sendToken.countdown.begin();
    this.formDevice.disable();
    this.formSendCode.enable();
    this.isDisabled = false;
    this.checkDefaultMethod();
  }

  hexToRgba(hex, alpha) {
    hex = hex.replace(/^#/, '')

    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  startProgress() {
    this.sendToken.countdown.config.leftTime = 180;
    const timer$ = interval(180);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 0.37) / 240;
      if (this.progressbarValue === 0) {
        this.sub.unsubscribe();
      }
    });
  }

  restartProgress() {
    this.sub.unsubscribe();
    this.startProgress();
  }

  // resendCode(data): void {
  //   this.loadTokenCode();
  //   this.restartProgress();
  //   this.sendToken.countdown.restart();
  //   this.sendToken.countdown.begin();
  // }

  resendCode() {
    if(this.progressValue > 0){
      this.toastAlert('Em breve você podera solicitar um novo código')
    } else {
      this.progressValue = 1
      this.startProgressBar();
      this.sendCode();
    }
  }

  startProgressBar() {
    const totalTime = 60; // Tempo total em segundos
    const updateInterval = 1000; // Intervalo de atualização em milissegundos
    const steps = totalTime / (updateInterval / 1000); // Número de etapas

    const interval = setInterval(() => {
      this.progressValue -= 1 / steps;

      if (this.progressValue <= 0) {
        this.progressValue = 0;
        clearInterval(interval);
      }
    }, updateInterval);
  }

  continueCountDown(event) {
    if (event.action === 'stop') {
      this.sendToken.countdown.restart();
    }
  }

  async continueSendCode() {

    this.codeValidation();

    // this.token =
    //   this.formSendCode.get('codeNumberOne').value +
    //   this.formSendCode.get('codeNumberTwo').value +
    //   this.formSendCode.get('codeNumberThree').value +
    //   this.formSendCode.get('codeNumberFour').value +
    //   this.formSendCode.get('codeNumberFive').value;

    // const validToken = await this.tokenCodeService.confirmToken(
    //   this.userProfile.uuidWhiteLabel,
    //   this.userProfile.uuidAccount,
    //   this.token,
    //   this.userProfile.userId
    // );

    // if (validToken) {
    //   const returnData = [
    //     {
    //       validToken,
    //       token: this.token,
    //     },
    //   ];
    //   this.modalController.dismiss(returnData);
    //   return;
    // } else {
    //   if (!this.sendToken.countdown.left) {
    //     this.alertAccountService.expiredCode();
    //     this.formSendCode.reset();
    //     return;
    //   }
    //   this.alertAccountService.invalidCode();
    // }
  }

  async codeValidation() {

    // this.load.show();
    
    let  codeEncrypt
   
      codeEncrypt = this.encrypt.codeEncrypt(this.code.join(''), environment.publicKeyApp)
      // codeEncrypt = this.code.join('')
    
    // o validType serve para dizer se existe ou não um método padrão já definido na conta.
    if(this.validType == true){
      const body: validateCode = {
        code: codeEncrypt.toString(),
        validationCodeIsEncrypted: true,
      }
  
      this.tokenCodeService.validateCode(body)
        .pipe(
          tap(res => {
            // this.load.hide();
            const validToken = res.validCode
  
            if (validToken == true) {
              let returnData = [{
                validToken: validToken,
                token: codeEncrypt,
                normalCode: this.code.join('')
              }
              ]
              this.modalController.dismiss(returnData)
              return
            } else {
  
              this.alertAccountService.invalidCode();
  
            }
  
          }), catchError(err => {
            // this.load.hide();
            this.alertAccountService.invalidCode();
            return of(err)
          })
        ).subscribe();
    } else {
      // this.defineMethodPreference(this.codeMethod, codeEncrypt, 'VALIDATETOKEN')
    } 
  }

  async dismissSuccess(status: boolean, token: string) {
    this.modalController.dismiss(status, token);
  }

  resetFlow() {
    this.progressbarValue = 1;
    this.formDevice.enable();
    this.formSendCode.disable();
    this.isDisabled = false;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sendToken.countdown.stop();
  }

  goBack() {
    this.modalController.dismiss();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        // this.loadTokenCode();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  // private loadTokenCode() {
  //   this.tokenCodeService
  //     .getTokenSmsUser({})
  //     .pipe(
  //       tap(console.log),
  //       catchError((error) => {
  //         return error;
  //       })
  //     )
  //     .subscribe((res) => (this.cellphone = res.phone));
  // }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  async checkDefaultMethod() {
    this.tokenCodeService.getCodeMethodPreference()
      .pipe(
        tap(res => {

        console.log('Teste', res)
        this.validType = true
        this.sendCode();

        if(res.codeType == 'EMAIL'){
          this.sendType = 'EMAIL'
        } else if (res.codeType == 'PHONE'){
          this.sendType = 'SMS'
        } else {
          this.sendType = 'CARD'
        }

        }), catchError(async err => {
          this.validType = false
          return of(err)
        })
      ).subscribe();
  }

  async sendCode() {
    const body: sendCodeV2 = {
      deviceManufacturer: this.device.manufacturer,
      deviceSerial: this.device.serial,
      devicePlatform: this.device.platform,
      deviceModel: this.device.model
    }

    this.tokenCodeService.newSendCode(body)
      .pipe(
        tap(async res => {

          this.sendFor = res.phone != undefined ? res.phone : res.email
          this.isSend = true

          if (res.codeMethod == 'CARD_PASSWORD') {
            this.lastCardDigits = res.cardLast4Digits
            this.digits.pop()
          }

        }), catchError(err => {
          console.log(err)
          this.toastAlert("Desculpe, tivemos um problema, tente novamente")
          return of(err)
        })
      ).subscribe()
  }

  focusInput(index: number) {
    const input = this.codigoInputs.toArray()[index];
    const nativeInput = input.nativeElement as HTMLInputElement;
    nativeInput.focus();
  }

  focusNextInput(index: number) {
    const nextIndex = index + 1;
    if (nextIndex < this.codigoInputs.length) {
      this.focusInput(nextIndex);
    }
  }

  focusPreviousInput(index: number) {
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      this.focusInput(previousIndex);
    }
  }

  handleKeyDown(event: KeyboardEvent, index: number) {
    const currentValue = this.code[index];

    // Verifica se a tecla pressionada é o backspace
    if (event.key === 'Backspace' && (currentValue === '' || currentValue === null)) {
      event.preventDefault(); // Evita que o navegador processe a tecla de volta
      this.focusPreviousInput(index);
    } else if (event.key.length === 1) {
      // Se uma tecla alfanumérica for pressionada, move para o próximo campo
      this.focusNextInput(index);
    }
  }

  handlePaste(event: ClipboardEvent, index: number) {
    event.preventDefault();  // Evita a ação padrão de colagem

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    for (let i in pastedData) {
      this.code[i] = pastedData[i]
      this.focusNextInput(parseInt(i));
    }
  }

  async toastAlert(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 2500,
      cssClass: 'customToastColor',
      mode: 'ios'
    })

    await toast.present();
  }
}
